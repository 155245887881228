import {Form, Formik, FormikHelpers} from 'formik'
import {useState} from 'react'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput, TextInputMask} from '../../../modules/Form'
import {executeRegisterInitialValues, executeRegisterValidation} from '../validations/usecases/ExecuteRegisterValidation'
import { HeaderTextWidget } from '../widgets/HeaderTextWidget'
import { cellphoneMask, CNPJMask, CPFMask } from '../../../modules/masks'
import axios from 'axios'
import Alert from './Alert'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function Register() {
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [base64Social, setBase64Social] = useState<string>();
    const [base64Person, setBase64Person] = useState<string>();
    const history = useHistory();

    async function handleSubmit(
        values: typeof executeRegisterInitialValues,
        { setStatus, setSubmitting }: FormikHelpers<typeof executeRegisterInitialValues>
    ) {
        try {
          setLoading(true);
          const {data} = await axios.post(`/auth/register`, {
                name: values.name,
                email: values.email,
                cd_cpf: values.cd_cpf,
                nm_company: values.nm_company,
                nm_fantasy: values.nm_fantasy,
                cd_phone_number: values.cd_phone_number,
                cd_cnpj: values.cd_cnpj,
                ds_contact_email: values.ds_contact_email,
                ds_path_social: base64Social,
                url_person: base64Person,
          });
          setAlertMessage(data.message)
          setOpenAlert(true);
        } catch (error: any) {
          setSubmitting(false);
          setStatus(error?.response.data.message || "Falha ao registrar a empresa");
        } finally {
          setLoading(false);
        }
    }

    function handleBase64(initialBase64: string) {
        const shortBase64 = initialBase64
          .split(";")[1]
          .substring(7, initialBase64.length);
        setBase64Social(shortBase64);
    }
    
    async function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        var reader = new FileReader();
        setLoading(true);
        try {
          if (!file) throw new Error("File not found");
          reader.readAsDataURL(file);
          reader.onload = () => {
            handleBase64(reader.result as string);
          };
          reader.onerror = (error) => {
            setBase64Social("");
            console.error(error);
            toast.error("Erro ao importar aquivo!");
          };
        } catch (error) {
          setBase64Social("");
          toast.error("Erro ao importar aquivo!");
        } finally {
          setLoading(false);
        }
    }

    function handleBase64Person(initialBase64: string) {
        const shortBase64 = initialBase64
          .split(";")[1]
          .substring(7, initialBase64.length);
        setBase64Person(shortBase64);
    }

    async function handleInputPerson(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        var reader = new FileReader();
        setLoading(true);
        try {
          if (!file) throw new Error("File not found");
          reader.readAsDataURL(file);
          reader.onload = () => {
            handleBase64Person(reader.result as string);
          };
          reader.onerror = (error) => {
            setBase64Person("");
            console.error(error);
            toast.error("Erro ao importar aquivo!");
          };
        } catch (error) {
            setBase64Person("");
          toast.error("Erro ao importar aquivo!");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="w-lg-600px bg-white rounded shadow-sm p-10 mx-auto">
            <div className='text-center mb-5'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/logo-auth.png')}
                    className='h-45px'
                />
            </div>
            <Formik
                initialValues={executeRegisterInitialValues}
                validationSchema={executeRegisterValidation}
                onSubmit={handleSubmit}
            >
                {({ handleChange }) => (
                    <Form className='form w-100' noValidate id='kt_login_signin_form'>
                        <HeaderTextWidget>
                            Bem-vindo(a) a RubyY Empresas!
                            <br />
                            O plano é não ter plano.
                        </HeaderTextWidget>
                        <div className='row'>
                            <div className='col-lg-12 mb-5'>
                                <TextInput
                                    name='name'
                                    label='Nome completo'
                                    placeholder='Entre com o nome completo'
                                    required
                                />
                            </div>
                            <div className='col-lg-6 mb-5'>
                                <TextInput
                                    label='E-mail'
                                    name='email'
                                    placeholder='Entre com o e-mail'
                                    required
                                />
                            </div>
                            <div className='col-lg-6 mb-5'>
                                <TextInputMask
                                    label='CPF'
                                    name='cd_cpf'
                                    placeholder='Entre com o CPF'
                                    required
                                    mask={CPFMask}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 mb-5'>
                                <TextInput
                                    name='nm_company'
                                    label='Razão Social'
                                    placeholder='Entre com a razão social'
                                    required
                                />
                            </div>
                            <div className='col-lg-6 col-12 mb-5'>
                                <TextInput
                                    name='nm_fantasy'
                                    label='Nome Fantasia'
                                    placeholder='Entre com o nome fantasia'
                                    required
                                />
                            </div>
                            <div className='col-lg-6 col-12 mb-5'>
                                <TextInputMask
                                    name='cd_phone_number'
                                    label='Telefone'
                                    placeholder='Entre com o telefone'
                                    mask={cellphoneMask}
                                    required
                                />
                            </div>
                            <div className='col-lg-6 col-12 mb-5'>
                                <TextInputMask
                                    name='cd_cnpj'
                                    label='CNPJ'
                                    placeholder='Entre com o CNPJ'
                                    mask={CNPJMask}
                                    required
                                />
                            </div>
                            <div className='col-lg-6 col-12 mb-5'>
                                <TextInput
                                    name='ds_contact_email'
                                    label='E-mail financeiro'
                                    required
                                    type='email'
                                    placeholder='Entre com o e-mail'
                                />
                            </div>
                            <div className='col-lg-12 mb-5'>
                                <TextInput
                                    name='ds_path_social'
                                    label='Contrato social'
                                    type='file'
                                    accept='.pdf'
                                    required
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleInput(e);
                                    }}
                                />
                            </div>
                            <div className='col-lg-12 mb-5'>
                                <TextInput
                                    name='url_person'
                                    label='Identidade do sócio e/ou procurador representante'
                                    type='file'
                                    accept='.pdf'
                                    required
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleInputPerson(e);
                                    }}
                                />
                            </div>
                            
                        </div>

                        <div className='text-center'>
                            <ButtonAuthSubmit buttonTitle='Cadastrar empresa' loading={loading} showMargin={false}></ButtonAuthSubmit>
                            <div className='my-5'>
                                <span className='fw-bolder'>Já faz parte? Entre e faça seu pedido!</span>
                            </div>
                            <ButtonAuthCancel 
                                buttonTitle='Acessar plataforma'
                                buttonUrl={'/autenticacao/entrar'}
                            />
                        </div>
                        <Alert
                            onClose={() => {
                            setOpenAlert(false);
                                history.push("/");
                            }}
                            title={alertMessage}
                            open={openAlert}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    )
}
