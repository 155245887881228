import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import FinancialRouter from './internal/FinancialRouter'
import { DashboardRouter } from './internal/DashboardRouter'
import ManagementsRouter from './internal/ManagementsRouter'
import { SecurityRouter } from './internal/SecurityRouter'
import CompanyPage from '../modules/companies/CompanyPage'

export function PrivateRoutes() {
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/meu-perfil' component={AccountPage} />

                <Route path='/painel' component={DashboardRouter} />
                <Route path='/financeiro' component={FinancialRouter} />
                <Route path='/gestao' component={ManagementsRouter} />
                <Route path='/seguranca' component={SecurityRouter} />

                <Route path='/empresa' component={CompanyPage} />

                <Redirect from='/autenticacao' to='/painel/administrativo' />
                <Redirect exact from='/' to='/painel/administrativo' />
                <Redirect to='error/404' />
            </Switch>
        </Suspense>
    )
}
